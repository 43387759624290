<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M38.6657 23.0139H12.5365L22.2766 13.2738C22.788 12.7623 22.788 11.9173 22.2766 11.3836C21.7651 10.8721 20.9201 10.8721 20.3864 11.3836L8.37804 23.3919C8.37804 23.3919 8.37804 23.3919 8.3558 23.4142C8.33357 23.4364 8.31133 23.4587 8.28909 23.5031L8.26685 23.5254C8.24461 23.5476 8.22238 23.5698 8.20014 23.5921C8.20014 23.5921 8.20014 23.6143 8.1779 23.6143C8.15566 23.6366 8.13343 23.681 8.13343 23.7033V23.7255C8.11119 23.7477 8.08895 23.7922 8.08895 23.8367C8.06671 23.8812 8.06671 23.9034 8.04448 23.9479C8.04448 23.9479 8.04448 23.9479 8.04448 23.9701C8.04448 24.0146 8.02224 24.0368 8.02224 24.0813V24.1035C8.02224 24.1258 8 24.1703 8 24.1925C8 24.237 8 24.2814 8 24.3259C8 24.3704 8 24.4149 8 24.4593C8 24.5038 8 24.5261 8.02224 24.5483V24.5705C8.02224 24.615 8.04448 24.6373 8.04448 24.6817C8.04448 24.6817 8.04448 24.6817 8.04448 24.704C8.06671 24.7484 8.06671 24.7707 8.08895 24.8152C8.08895 24.8152 8.08895 24.8152 8.08895 24.8374C8.11119 24.8819 8.11119 24.9041 8.13343 24.9486V24.9708C8.15566 24.9931 8.1779 25.0375 8.1779 25.0598C8.1779 25.0598 8.1779 25.082 8.20014 25.082C8.22238 25.1042 8.24461 25.1265 8.24461 25.1487L8.26685 25.171C8.28909 25.1932 8.31133 25.2154 8.33356 25.2377L8.3558 25.2599L20.3641 37.2682C20.631 37.5351 20.9646 37.6685 21.2981 37.6685C21.6317 37.6685 21.9875 37.5351 22.2321 37.2682C22.7436 36.7568 22.7436 35.9118 22.2321 35.378L12.5365 25.6824H38.6657C39.3996 25.6824 40 25.082 40 24.3482C40 23.6143 39.3996 23.0139 38.6657 23.0139Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-arrow-left-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
